export default function () {
    const locationInfo = useStateLocationInfo();
    const regions = useStateRegionInfo();

    return computed<RegionInfo | null>({
        get: () =>
            (regions.value ? regions.value.find((c) => c.RegionId == locationInfo.value.RegionId) : null) ?? null,
        set: async (region: RegionInfo | null) => {
            if (!region) return null;

            const body = new FormData();
            body.append("RegionId", region.RegionId.toString());
            await $fetch("/api/locale-service/SetRegion", { method: "POST", body });
            locationInfo.value.RegionId = region.RegionId;
            reloadNuxtApp({ force: true });
        },
    });
}
